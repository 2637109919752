import {
  ThemeMode,
  UserDefinedTheme,
  Padding,
  BorderStyleBlock,
  TextStyleBlock,
} from "@superblocksteam/shared";
import tinycolor from "tinycolor2";
import { BASE_THEME_NEW_APPS, BOX_SHADOWS, BUTTON_PADDING } from "./constants";
import { generateThemeColors } from "./generateColors";
import { generateTypographies } from "./generateTypographies";
import { getAvailableFontFamilies } from "./typefaces/utils";
import { GeneratedTheme } from "./types";
import { getDefaultTypographies, getThemeWithVersion } from "./utils";

const prefersDarkMode = () => {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    return true;
  }
  return false;
};

export function generateTheme(
  userDefinedTheme: UserDefinedTheme,
  overrideMode?: ThemeMode,
): GeneratedTheme {
  const {
    typeFace,
    primaryColor,
    mode: storedMode,
    borderRadius,
    padding,
    palette,
  } = userDefinedTheme;
  const mode = overrideMode || storedMode;
  const isDarkMode =
    mode === ThemeMode.DARK || (mode === ThemeMode.AUTO && prefersDarkMode());
  const generatedColors = generateThemeColors({
    themePrimaryColor: primaryColor,
    palette,
    isDarkMode,
  });

  const defaultBorderStyle: BorderStyleBlock = {
    borderWidth: { value: 1, mode: "px" },
    borderStyle: "solid",
    borderColor: {
      default: generatedColors.neutral100,
    },
    boxShadow: {
      default: "none",
    },
    borderRadius,
  };
  const generatedTypographies = generateTypographies(
    userDefinedTheme.typographies ??
      getDefaultTypographies(userDefinedTheme.version),
    {
      colors: generatedColors,
      defaultFontFamily: typeFace,
    },
  );
  const bodyTypeface: TextStyleBlock = generatedTypographies.body2;
  // For apps created without theme, the userDefinedTheme will always include padding of 16x12
  // If the app was initialized with theme, not all apps had padding set
  const defaultPadding = padding || (BASE_THEME_NEW_APPS.padding as Padding);

  // used for things like table footer content, table filter menu, etc
  const builtinBodyTypeface: TextStyleBlock = {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    fontFamily: typeFace,
    textColor: {
      default: generatedColors.neutral700,
    },
  };
  return {
    version: getThemeWithVersion(userDefinedTheme).version,
    colors: generatedColors,
    fontFamily: typeFace,
    availableFonts: getAvailableFontFamilies(userDefinedTheme.availableFonts),
    borderRadius,
    mode: isDarkMode ? "DARK" : "LIGHT",
    defaultBorder: defaultBorderStyle,
    padding: defaultPadding,
    builtinTypographies: {
      body: builtinBodyTypeface,
      system: {
        ...defaultBorderStyle,
        ...builtinBodyTypeface,
        textColor: {
          default: generatedColors.neutral400,
        },
        backgroundColor: {
          default: generatedColors.neutral25,
        },
        padding: {
          left: { value: 4, mode: "px" },
          right: { value: 4, mode: "px" },
          top: { value: 0, mode: "px" },
          bottom: { value: 0, mode: "px" },
        },
      },
    },
    typographies: generatedTypographies,
    buttons: {
      primary: {
        ...generatedTypographies.buttonLabel,
        padding: BUTTON_PADDING,
        borderWidth: { mode: "px", value: 1 },
        borderStyle: "solid",
        borderRadius,
        textColor: {
          default: generatedColors.contrastText,
          disabled: generatedColors.neutral300,
        },
        backgroundColor: {
          default: generatedColors.primary500,
          disabled: generatedColors.neutral100,
          hover: generatedColors.primary600,
          active: generatedColors.primary700,
        },
        boxShadow: {
          default: "none",
          hover: `0px 3px 8px 0px ${tinycolor(generatedColors.primary500)
            .setAlpha(0.32)
            .toRgbString()}`,
        },
        borderColor: {
          default: generatedColors.primary500,
          disabled: generatedColors.neutral100,
          hover: generatedColors.primary600,
          active: generatedColors.primary700,
        },
      },
      secondary: {
        ...generatedTypographies.buttonLabel,
        padding: BUTTON_PADDING,
        borderWidth: { mode: "px", value: 1 },
        borderStyle: "solid",
        borderRadius,
        textColor: {
          default: generatedColors.primary500,
          disabled: generatedColors.neutral300,
          hover: generatedColors.primary600,
          active: generatedColors.primary700,
        },
        backgroundColor: {
          default: "transparent",
          disabled: generatedColors.neutral100,
          hover: "transparent",
          active: "transparent",
        },
        borderColor: {
          default: generatedColors.primary500,
          disabled: generatedColors.neutral100,
          hover: generatedColors.primary600,
          active: generatedColors.primary700,
        },
        boxShadow: {
          default: "none",
        },
      },
      secondaryNeutral: {
        ...generatedTypographies.buttonLabel,
        padding: BUTTON_PADDING,
        borderWidth: { mode: "px", value: 1 },
        borderStyle: "solid",
        borderRadius,
        textColor: {
          default: generatedColors.neutral700,
          disabled: generatedColors.neutral300,
          hover: generatedColors.neutral900,
          active: generatedColors.neutral900,
        },
        backgroundColor: {
          default: "transparent",
          disabled: generatedColors.neutral100,
          hover: generatedColors.neutral25,
          active: generatedColors.neutral50,
        },
        borderColor: {
          default: generatedColors.neutral100,
          disabled: generatedColors.neutral100,
          hover: generatedColors.neutral100,
          active: generatedColors.neutral100,
        },
        boxShadow: {
          default: "none",
        },
      },
      tertiary: {
        ...generatedTypographies.buttonLabel,
        padding: BUTTON_PADDING,
        borderWidth: { mode: "px", value: 1 },
        borderStyle: "solid",
        borderRadius,
        textColor: {
          default: generatedColors.primary500,
          disabled: generatedColors.neutral300,
          hover: generatedColors.primary600,
          active: generatedColors.primary700,
        },
        backgroundColor: {
          default: "transparent",
        },
        borderColor: {
          default: "transparent",
        },
        boxShadow: {
          default: "none",
        },
      },
      system: {
        ...bodyTypeface,
        padding: {}, // no padding
        borderWidth: { mode: "px", value: 0 },
        borderStyle: "solid",
        borderRadius,
        textColor: {
          default: generatedColors.neutral300,
        },
        backgroundColor: {
          default: generatedColors.neutral25,
          disabled: generatedColors.neutral100,
          hover: generatedColors.neutral25,
          active: generatedColors.neutral50,
        },
        borderColor: {
          default: "transparent",
        },
        boxShadow: {
          default: `inset 0 0 0 1px ${generatedColors.neutral100}`,
        },
      },
    },
    inputs: {
      input: {
        ...generatedTypographies.inputText,
        ...defaultBorderStyle,
        padding: {
          left: { mode: "px", value: 10 },
          right: { mode: "px", value: 10 },
          top: { mode: "px", value: 6 },
          bottom: { mode: "px", value: 6 },
        },
        textColor: {
          ...generatedTypographies.inputText.textColor,
          disabled: generatedColors.neutral300,
        },
        backgroundColor: {
          default: generatedColors.neutral,
          disabled: generatedColors.neutral100,
        },
        borderColor: {
          default: generatedColors.neutral100,
          disabled: generatedColors.neutral100,
          hover: generatedColors.primary500,
          active: generatedColors.primary500,
          error: generatedColors.danger,
        },
        boxShadow: {
          default: "none",
          active: `0px 1px 3px 0px ${tinycolor(generatedColors.primary500)
            .setAlpha(0.24)
            .toRgbString()}`,
        },
      },
      switch: {
        ...bodyTypeface,
        height: { mode: "px", value: 20 },
        width: { mode: "px", value: 32 },
        borderWidth: { mode: "px", value: 1 },
        borderRadius: { mode: "px", value: 10 }, // half of height
        borderStyle: "solid",
        padding: {}, // 0px
        textColor: {
          // for a switch, this represents the color of the slider
          default: generatedColors.neutral,
          disabled: generatedColors.neutral300,
        },
        backgroundColor: {
          default: generatedColors.neutral100,
          disabled: generatedColors.neutral100,
          hover: generatedColors.neutral200,
          active: generatedColors.primary500,
          activeHover: generatedColors.primary600,
          error: generatedColors.dangerLight,
        },
        borderColor: {
          default: generatedColors.neutral100,
          disabled: generatedColors.neutral100,
          hover: generatedColors.neutral200,
          active: generatedColors.primary500,
          activeHover: generatedColors.primary600,
          error: generatedColors.danger,
        },
        boxShadow: {
          default: "none",
        },
      },
      radio: {
        ...bodyTypeface,
        height: { mode: "px", value: 16 },
        width: { mode: "px", value: 16 },
        borderWidth: { mode: "px", value: 1 },
        borderRadius,
        borderStyle: "solid",
        padding: {}, // 0px
        textColor: {
          // represents the checkmark color
          default: generatedColors.neutral,
          disabled: generatedColors.neutral300,
          error: generatedColors.danger,
          active: generatedColors.primary500,
          activeHover: generatedColors.primary600,
        },
        backgroundColor: {
          default: generatedColors.neutral,
          hover: generatedColors.neutral25,
          disabled: generatedColors.neutral100,
          active: generatedColors.neutral,
          activeHover: generatedColors.neutral,
        },
        borderColor: {
          default: generatedColors.neutral200,
          hover: generatedColors.neutral300,
          disabled: generatedColors.neutral100,
          active: generatedColors.primary500,
          activeHover: generatedColors.primary600,
        },
        boxShadow: {
          default: "none",
        },
      },
      checkbox: {
        ...bodyTypeface,
        height: { mode: "px", value: 16 },
        width: { mode: "px", value: 16 },
        borderWidth: { mode: "px", value: 1 },
        borderRadius,
        borderStyle: "solid",
        padding: {}, // 0px
        textColor: {
          // represents the checkmark color
          default: generatedColors.contrastText,
          disabled: generatedColors.neutral300,
          error: generatedColors.danger,
        },
        backgroundColor: {
          default: generatedColors.neutral,
          disabled: generatedColors.neutral100,
          hover: generatedColors.neutral25,
          active: generatedColors.primary500,
          activeHover: generatedColors.primary600,
          error: generatedColors.dangerLight,
        },
        borderColor: {
          default: generatedColors.neutral100,
          disabled: generatedColors.neutral100,
          hover: generatedColors.neutral200,
          active: generatedColors.primary500,
          activeHover: generatedColors.primary600,
          error: generatedColors.danger,
        },
        boxShadow: {
          default: "none",
        },
      },
    },
    popover: {
      ...bodyTypeface,
      borderWidth: { mode: "px", value: 0 },
      borderStyle: "solid",
      borderRadius,
      padding: {
        left: { mode: "px", value: 6 },
        right: { mode: "px", value: 6 },
        top: { mode: "px", value: 6 },
        bottom: { mode: "px", value: 6 },
      },
      borderColor: {
        default: "transparent",
      },
      backgroundColor: {
        default: generatedColors.neutral,
      },
      boxShadow: {
        default: isDarkMode
          ? BOX_SHADOWS.popover.dark
          : BOX_SHADOWS.popover.light,
      },
    },
    dropdown: {
      caratIcon: {
        iconColor: {
          default: generatedColors.neutral300,
          active: generatedColors.primary500,
        },
      },
      closeIcon: {
        iconColor: {
          default: generatedColors.neutral300,
          hover: generatedColors.neutral400,
          active: generatedColors.neutral500,
        },
      },
      menuItem: {
        ...bodyTypeface,
        ...defaultBorderStyle,
        borderColor: {
          default: "transparent",
        },
        padding: {
          left: { mode: "px", value: 10 },
          right: { mode: "px", value: 10 },
          top: { mode: "px", value: 6 },
          bottom: { mode: "px", value: 6 },
        },
        backgroundColor: {
          default: generatedColors.neutral,
          hover: generatedColors.neutral50,
          active: generatedColors.neutral50,
        },
        textColor: {
          ...bodyTypeface.textColor,
          disabled: generatedColors.neutral300,
        },
        boxShadow: {
          default: "none",
        },
      },
      select: {
        ...bodyTypeface,
        ...defaultBorderStyle,
        padding: {
          left: { mode: "px", value: 6 },
          right: { mode: "px", value: 6 },
          top: { mode: "px", value: 0 },
          bottom: { mode: "px", value: 0 },
        },
        textColor: {
          ...bodyTypeface.textColor,
          disabled: generatedColors.neutral300,
        },
        backgroundColor: {
          default: generatedColors.neutral,
          disabled: generatedColors.neutral100,
          hover: generatedColors.neutral,
        },
        borderColor: {
          default: generatedColors.neutral100,
          disabled: generatedColors.neutral100,
          hover: generatedColors.primary500,
          active: generatedColors.primary500,
        },
        boxShadow: {
          default: "none",
        },
      },
      tag: {
        ...bodyTypeface,
        ...defaultBorderStyle,
        padding: {
          left: { mode: "px", value: 6 },
          right: { mode: "px", value: 6 },
          top: { mode: "px", value: 4 },
          bottom: { mode: "px", value: 4 },
        },
        textColor: {
          ...bodyTypeface.textColor,
          disabled: generatedColors.neutral300,
        },
        backgroundColor: {
          default: generatedColors.neutral,
          disabled: generatedColors.neutral100,
          hover: generatedColors.neutral25,
        },
        borderColor: {
          default: generatedColors.neutral100,
          disabled: generatedColors.neutral200,
        },
        boxShadow: {
          default: "none",
        },
      },
    },
    datepicker: {
      cell: {
        ...bodyTypeface,
        ...defaultBorderStyle,
        padding: {}, // 0px
        textColor: {
          ...bodyTypeface.textColor,
          disabled: generatedColors.neutral300,
          active: generatedColors.contrastText,
          activeHover: generatedColors.contrastText,
        },
        backgroundColor: {
          default: "transparent",
          hover: generatedColors.neutral25,
          active: generatedColors.primary500,
          activeHover: generatedColors.primary600,
        },
        borderColor: {
          default: "transparent",
          hover: generatedColors.neutral100,
          active: generatedColors.primary500,
          activeHover: generatedColors.primary600,
        },
      },
    },
    container: {
      section: {
        ...bodyTypeface,
        backgroundColor: {
          default: generatedColors.appBackground,
        },
        borderWidth: { value: 0, mode: "px" },
        borderStyle: "solid",
        borderColor: {
          default: generatedColors.neutral100,
        },
        boxShadow: {
          default: "none",
        },
        borderRadius: { value: 0, mode: "px" },
        padding: {}, // 0px
      },
      notification: {
        ...bodyTypeface,
        borderColor: { default: "transparent" },
        boxShadow: {
          default:
            mode === "DARK" ? BOX_SHADOWS.modal.dark : BOX_SHADOWS.modal.light,
        },
        borderRadius,
        borderWidth: { mode: "px", value: 0 },
        padding: {}, // 0px
        borderStyle: "solid",
        backgroundColor: {
          default: generatedColors.neutral,
        },
      },
      default: {
        ...defaultBorderStyle,
        padding: {}, // 0px
        ...bodyTypeface,
        backgroundColor: {
          default: generatedColors.neutral,
          active: generatedColors.neutral50,
        },
      },
      canvas: {
        ...bodyTypeface,
        borderColor: { default: generatedColors.neutral100 },
        boxShadow: { default: "none" },
        borderRadius: { mode: "px", value: 0 },
        borderWidth: { mode: "px", value: 0 },
        padding: defaultPadding,
        borderStyle: "solid",
        backgroundColor: {
          default: "none",
          active: generatedColors.neutral50,
        },
      },
      tab: {
        ...bodyTypeface,
        padding: {
          left: { value: 16, mode: "px" },
          right: { value: 16, mode: "px" },
          top: { value: 10, mode: "px" },
          bottom: { value: 0, mode: "px" },
        },
        textColor: {
          default: generatedColors.neutral500,
          active: generatedColors.neutral900,
        },
        borderStyle: "solid",
        borderWidth: { mode: "px", value: 1 },
        borderRadius: { mode: "px", value: 0 }, // unused
        boxShadow: {
          default: "none",
        },
        backgroundColor: {
          default: "transparent",
        },
        borderColor: {
          default: generatedColors.neutral50,
          active: generatedColors.primary500,
        },
      },
      modal: {
        borderColor: { default: "transparent" },
        borderStyle: "solid",
        borderWidth: { mode: "px", value: 0 }, // unused
        borderRadius,
        padding: {}, // 0px
        ...bodyTypeface,
        backgroundColor: {
          default: generatedColors.neutral,
        },
        boxShadow: {
          default: isDarkMode
            ? BOX_SHADOWS.modal.dark
            : BOX_SHADOWS.modal.light,
        },
      },
      closeIcon: {
        iconColor: {
          default: generatedColors.neutral400,
          hover: generatedColors.neutral700,
        },
      },
    },
    icon: {
      default: {
        iconColor: {
          default: generatedColors.neutral700,
          active: generatedColors.primary500,
          disabled: generatedColors.neutral300,
        },
      },
    },
  };
}
