import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";

export enum PerformanceTransactionName {
  DATA_TREE_EVALUATION = "DATA_TREE_EVALUATION",
  CREATE_DEPENDENCIES = "CREATE_DEPENDENCIES",
  EXECUTE_PAGE_LOAD_ACTIONS = "EXECUTE_PAGE_LOAD_ACTIONS",
  ENTITY_EXPLORER = "ENTITY_EXPLORER",
  SIDE_BAR_MOUNT = "SIDE_BAR_MOUNT",
  EXECUTE_ACTION = "EXECUTE_ACTION",
  CHANGE_API_SAGA = "CHANGE_API_SAGA",
  SYNC_PARAMS_SAGA = "SYNC_PARAMS_SAGA",
  FETCH_PAGE_LIST_API = "FETCH_PAGE_LIST_API",
  FETCH_PAGE_ACTIONS_API = "FETCH_PAGE_ACTIONS_API",
  SAVE_PAGE_API = "SAVE_PAGE_API",
  UPDATE_ACTION_API = "UPDATE_ACTION_API",
  USER_ME_API = "USER_ME_API",
  UNDO_REDO = "UNDO_REDO",
  EXTRACT_BINDINGS_FROM_API = "EXTRACT_BINDINGS_FROM_API",
  RENAME_WIDGETS = "RENAME_WIDGETS",
  LOAD_AUTH0 = "LOAD_AUTH0",
  INITIALIZE_APP_SAGA = "INITIALIZE_APP_SAGA",
  FIRST_EVALUATION_ON_LOAD = "FIRST_EVALUATION_ON_LOAD",
}

export enum PerformanceName {
  API_RESPONSE_RECEIVED = "API_RESPONSE_RECEIVED",
  AI_ASSISTANT_OPENED = "AI_ASSISTANT_OPENED",
  AI_RESPONSE_GENERATED = "AI_RESPONSE_GENERATED",
  AI_RESPONSE_USED = "AI_RESPONSE_USED",
  POSTMESSAGE = "POSTMESSAGE",
}

interface PerfLog {
  sentrySpan: Sentry.Span | Sentry.Transaction;
  skipLog?: boolean;
  eventName: string;
}

class PerformanceTracker {
  private static perfAsyncMap: Map<string, PerfLog> = new Map();

  static track(
    eventName: PerformanceName,
    context: Record<string, number | string> | undefined,
  ) {
    datadogRum.addAction(eventName, context);
  }

  static startAsyncTracking = (
    eventName: PerformanceTransactionName,
    data?: any,
    uniqueId?: string,
    parentEventId?: string,
    skipLog = false,
  ) => {
    if (!parentEventId) {
      const newTransaction = Sentry.startTransaction({ name: eventName });
      newTransaction?.setData("startData", data);
      PerformanceTracker.perfAsyncMap.set(uniqueId ? uniqueId : eventName, {
        sentrySpan: newTransaction,
        eventName: eventName,
        skipLog: skipLog,
      });
    } else {
      const perfLog = PerformanceTracker.perfAsyncMap.get(parentEventId);
      const childSpan = perfLog?.sentrySpan.startChild({
        op: eventName,
        data: data,
      });
      if (childSpan) {
        PerformanceTracker.perfAsyncMap.set(uniqueId ? uniqueId : eventName, {
          sentrySpan: childSpan,
          eventName: eventName,
          skipLog: skipLog,
        });
      }
    }

    datadogRum.addAction("START_" + eventName, {
      uniqueId,
      data,
    });
  };

  static stopAsyncTracking(
    eventName: PerformanceTransactionName,
    data?: any,
    uniqueId?: string,
  ) {
    const perfLog = PerformanceTracker.perfAsyncMap.get(
      uniqueId ? uniqueId : eventName,
    );
    if (perfLog && perfLog.sentrySpan) {
      const currentSpan = perfLog.sentrySpan;
      currentSpan.setData("endData", data);
      currentSpan.finish();
      if (!perfLog?.skipLog) {
        PerformanceTracker.printDuration(
          perfLog.eventName,
          0,
          currentSpan.startTimestamp,
          currentSpan.endTimestamp,
          true,
        );
        const duration =
          ((currentSpan.endTimestamp || 0) - currentSpan.startTimestamp) * 1000;

        datadogRum.addAction(perfLog.eventName, {
          uniqueId,
          duration,
          data,
        });
      }
    }
  }

  static generateSpaces(num: number) {
    let str = "";
    for (let i = 0; i < num; i++) {
      str += "\t";
    }
    return str;
  }

  static printDuration(
    eventName: string,
    level: number,
    startTime: number,
    endTime?: number,
    isAsync?: boolean,
  ) {
    const duration = ((endTime || 0) - startTime) * 1000;
    const spaces = PerformanceTracker.generateSpaces(level);
    console.debug(
      spaces +
        "Transaction " +
        eventName +
        (isAsync ? " (async) " : " ") +
        "finished in " +
        duration.toFixed(0) +
        "ms",
    );
  }
}

export default PerformanceTracker;
